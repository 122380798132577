/* File includes any Material UI styling overwrites */
@use '@angular/material' as mat;

@import '@angular/material/theming';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css'; // Material Theme
@import './variables';
@import 'fonts';
@import './mixins';

// tel input
.iti__country-list {
    border-radius: $border-radius;
    background-color: $DD_GRAY !important;
    border: none !important;

    .iti__divider {
        background-color: $DD_GRAY;
        border-bottom: 1px solid $GRAY;
    }

    .iti__country {
        background-color: $DD_GRAY;
        font-size: $font-size-12;
        padding: 10px;
    }
}

// snack-bar
.mat-snack-bar-container {
    color: $WHITE !important;
    margin-bottom: 35px !important; 
    font-weight: $font-weight-200 !important;

    &.mat-snackbar-success {
        background-color: $GREEN !important;

        .mat-simple-snackbar-action > button {
            color: $WHITE;
            font-weight: bold;
    
            &::before {
                content: '\2715';
            }
    
            .mat-button-wrapper {
                display: none; 
            }
        }
    }

    &.mat-snackbar-error {
        background-color: $RED !important;

        .mat-simple-snackbar-action > button {
            color: $WHITE;
            font-weight: bold;
    
            &::before {
                content: '\2715';
            }
    
            .mat-button-wrapper {
                display: none; 
            }
        }
    }

    &.mat-snackbar-prompt {
        background-color: $LT_BLUE !important;
        .mat-simple-snackbar-action > button {
            color: $WHITE;
            font-weight: bold;
        }
    }
}

// Misc.
.mat-warn,
.mat-form-field-invalid,
.mat-error,
.mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
    caret-color: $RED!important;
    color: $RED!important;
}
.mat-error.form-error{
    font-size: 12px;
}

.error-container {
    position: absolute;
    &.block {
        position: relative;
        display: block;
    }
}

// <mat-select>
.dropdown {
    &.mat-form-field-disabled {
        opacity: .6;
        
    }
    .mat-form-field-wrapper {
        padding: 0!important;
        .mat-form-field-underline {
            display: none;
        }
        .mat-form-field-infix {
            border: none;
            padding: 0;
            .mat-form-field-label {
                padding: 0 10px;
            }
            .mat-select-value {
                color: unset;
                padding: 8.5px 10px;
                font-weight: 200;
            }
            .mat-select-arrow {
                color: unset !important;
            }
        }
    }
}
.hide-label .dropdown {
    .mat-form-field-infix {
        width: auto;
    }
}

.dropdown-mat-option {
    height: auto!important;
    min-height: 3em;
}

// Dropdown overlay options
.cdk-overlay-pane { 
    transform:translateX(-1px) translateY(-1px) !important;
    .mat-select-panel { 
        min-width:calc(100% + 2px) !important;
    }
}

@mixin matInputs {
    position: relative;
    .mat-form-field-wrapper {
        padding: 0!important;
        .mat-form-field-infix {
            width: 100%;
            padding: 0;
            input {
                font-weight: 200;
            }
            .mat-form-field-label {
                padding: 0 10px;
            }
        }
    }
    .mat-input-element {
        @include input;
    }
    .mat-form-field-underline {
        display: none!important;
    }
}

// <input>
.date-picker-input {
    .mat-form-field-suffix {
         position: absolute;
         right: 4px;
         top: 18px;
         width: 25px;
     }
     @include matInputs
 }
.text-input {
    .mat-form-field-subscript-wrapper {
        margin-top: 2em!important;
        padding: 0 10px!important;
        font-size: 11px!important;
    }
    @include matInputs
}

// <input> float labels
.mat-form-field-should-float .mat-form-field-label {
    color: $WHITE!important;
    font-size: 11px!important;
    transform: translateY(-1.3em)!important;
}

.mat-input-element:disabled,
.mat-input-element:disabled + .mat-form-field-label-wrapper {
    opacity: $disabled-opacity;
}

.form {
    &.readonly {
        mat-form-field {
            pointer-events: none;
        }
        .mat-input-element {
            border: none;
        }
    }
}

.mat-checkbox {
    .mat-checkbox-frame {
        border-color: $GRAY;
    }
}
.mat-checkbox-checked {
    .mat-checkbox-background {
        background-color: $GRAY!important;
    }
}

.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
        background-color: rgba(255, 255, 255, .7)!important;
        .mat-slide-toggle-thumb {
            background-color: white!important;
        }
    }
}

// mat-chip-list
.mat-chip-list-wrapper {
    // Same as the text input look
    color: $WHITE;
    background-color: $WHITE_15P;
    border-radius: 3px;
    font-size: 16px;
    min-height: 42px;
    margin: 0!important; // default is -4px

    .mat-chip-input {
        padding: 8px 10px;
    }
}
.mat-form-field-type-mat-chip-list {
    .mat-form-field-underline {
        display: none;
    }
}
.app-chip-list {
    input {
        font-weight: 200;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0!important;
    }
    .mat-form-field-label-wrapper {
        margin-top: -5px;
        margin-left: -2px;
    }
    .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
        padding: 14px;
        font-weight: 400;
        color: $WHITE;
    }
}
.mat-button-toggle-group {
    border: none!important;
}
.mat-button-toggle {
    &.mat-button-toggle-appearance-standard {
        opacity: $disabled-opacity;
        background-color: $GRAY;
        color: white;
    }
    &.mat-button-toggle-checked {
        opacity: 1;
    }
}


// Custom Theming
$pk-blue-palette: (
    50 : #e7ebfe,
    100 : #c2cefc,
    200 : #9aaefb,
    300 : #718df9,
    400 : #5274f7,
    500 : #345cf6,
    600 : #2f54f5,
    700 : #274af3,
    800 : #2141f2,
    900 : #1530ef,
    A100 : #ffffff,
    A200 : #ebedff, // MAT-SLIDE-TOGGLE = TRUE
    A400 : #b8c0ff,
    A700 : #9fa9ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$pk-purple-palette: (
    50 : #e7ebfe,
    100 : #c2cefc,
    200 : #9aaefb,
    300 : #718df9,
    400 : #5274f7,
    500 : #345cf6,
    600 : #2f54f5,
    700 : #274af3,
    800 : #2141f2,
    900 : #1530ef,
    A100 : #ffffff,
    A200 : #345cf6, // MAT-SLIDE-TOGGLE = TRUE
    A400 : #b8c0ff,
    A700 : #9fa9ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$pk-red-palette: (
    50 : #fce6e9,
    100 : #f7bfc9,
    200 : #f295a5,
    300 : #ed6b80,
    400 : #e94b65,
    500 : #e52b4a,
    600 : #e22643,
    700 : #de203a,
    800 : #da1a32,
    900 : #d31022,
    A100 : #ffffff,
    A200 : #ffced1,
    A400 : #ff9ba2,
    A700 : #ff818a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$custom-typography: mat.define-typography-config($font-family: '"Gilmer", sans-serif;');
@include mat.core();

$custom-theme-primary: mat.define-palette($pk-blue-palette);
$custom-theme-accent: mat.define-palette($pk-purple-palette, A200, A100, A400);
$custom-theme-warn: mat.define-palette($pk-red-palette);
$custom-theme: mat.define-light-theme((
    color: (
        primary: $custom-theme-primary,
        accent: $custom-theme-accent,
        warn: $custom-theme-warn
    ),
    typography: $custom-typography
));
@include mat.all-component-themes($custom-theme);

.teams, .teams-dark {
    .mat-menu-panel {
        border-radius: 4px;
        border: 1px solid var(--mat-menu-border-color);
        background-color: var(--mat-menu-bkg-color);
        box-shadow: 0px 0px 20px 0px var(--bkg-shadow-color);
        min-width: 200px;
        min-height: unset;

        .mat-menu-item {
            color: var(--mat-menu-item-color);
            font-size: 12px;
            font-weight: 200;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            gap: 10px;
            height: 35px;

            &:hover {
                background-color: var(--mat-menu-item-hover-bkg-color);
            }

            .icon {
                width: 20px;
            }
        }
    }

    .mat-tooltip {
        color: var(--color);
        background-color: var(--mat-tooltip-bkg-color);
        border-radius: 0;
        font-weight: 200;
        font-size: 10px;
        box-shadow: 0px 0px 20px 0px var(--bkg-shadow-color);
    }

    .mat-progress-spinner circle{
        stroke: var(--spinner-stroke-color);
    }
}
