@import './variables';
@import './mixins';


.blue-lt { color: $LT_BLUE }

.pen{
    pointer-events: none;
}
.cPointer{
    cursor: pointer;
}
.cDefault{
    cursor: default;
}
.cMove{
    cursor: move;
}
.taC { text-align: center; }
.taL { text-align: left; }
.taR { text-align: right; }
.ttu { text-transform: uppercase; }
.fB100 { flex-basis: 100% }

.invisible {
    visibility: hidden !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.overHidden {
    overflow: hidden;
}

.overAuto {
    overflow: auto;
}

.flexRow {
    display: flex;
    flex-flow: row nowrap;
}

.flex1 {
    flex: 1;
}

.aiC {
    align-items: center;
}

.jcS {
    justify-content: start !important;
}

.jcC {
    justify-content: center;
}

.flexColumn {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.fg1 {
    flex-grow: 1;
}

.fg0 {
    flex-grow: 0;
}

.fg05 {
    flex-grow: 0.5;
}

.jcSA {
    justify-content: space-around!important;
}

.jcFS {
    justify-content: flex-start!important;
}

.jcFE {
    justify-content: flex-end!important;
}

.hAuto {
    height: auto;
}

.mA {
    margin: auto;
}

.pRel {
    position: relative;
}

.uppercase {
    text-transform: uppercase;
}

.no-data {
    @include noDataText;
}

.bgCenter  {
    background-size: cover;
    background-position: center;
}

@for $i from 1 through 30 {
    $value: $i * 5;
    .gap#{$value} {
        gap: $value * 1px;
    }
}

// Font weights
@for $i from 0 through 500 {
    $value: $i + 100;
    .fw#{$value} {
        font-weight: $value;
    }
}

// START Widths
@for $i from 1 through 10 {
    $value: $i * 10;
    .w#{$value}p {
        width: $value * 1%;
    }
}
// END Widths

// START Heights
@for $i from 1 through 10 {
    $value: $i * 10;
    .h#{$value}p {
        height: $value * 1%;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .h#{$value} {
        height: $value * 1px;
    }
}
// END Heights

// START Padding
.p0 {
    padding: 0!important;
}
// Padding left
@for $i from 0 through 4 {
    $value: $i * 2;
    .pL#{$value} {
        padding-left: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pL#{$value} {
        padding-left: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pL#{$value}p {
        padding-left: $value * 1%;
    }
}

// Padding right
@for $i from 0 through 4 {
    $value: $i * 2;
    .pR#{$value} {
        padding-right: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pR#{$value} {
        padding-right: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pR#{$value}p {
        padding-right: $value * 1%;
    }
}

// Padding top
@for $i from 0 through 4 {
    $value: $i * 2;
    .pT#{$value} {
        padding-top: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pT#{$value} {
        padding-top: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pT#{$value}p {
        padding-top: $value * 1%;
    }
}

// Padding bottom
@for $i from 0 through 4 {
    $value: $i * 2;
    .pB#{$value} {
        padding-bottom: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pB#{$value} {
        padding-bottom: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pB#{$value}p {
        padding-bottom: $value * 1%;
    }
}
// END Padding

// START Margin
.m0 {
    margin: 0;
}
.mLa {
    margin-left: auto;
}
.mRa {
    margin-right: auto;
}
.mTa {
    margin-top: auto;
}
// Margin left
@for $i from 0 through 4 {
    $value: $i * 2;
    .mL#{$value} {
        margin-left: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mL#{$value} {
        margin-left: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mL#{$value}p {
        margin-left: $value * 1%;
    }
}

// Margin right
@for $i from 0 through 4 {
    $value: $i * 2;
    .mR#{$value} {
        margin-right: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mR#{$value} {
        margin-right: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mR#{$value}p {
        margin-right: $value * 1%;
    }
}

// Margin top
@for $i from 0 through 4 {
    $value: $i * 2;
    .mT#{$value} {
        margin-top: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mT#{$value} {
        margin-top: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mT#{$value}p {
        margin-top: $value * 1%;
    }
}

// Margin bottom
@for $i from 0 through 4 {
    $value: $i * 2;
    .mB#{$value} {
        margin-bottom: $value * 1px;
    }
}

@for $i from 1 through 50 {
    $value: $i * 5;
    .mB#{$value} {
        margin-bottom: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mB#{$value}p {
        margin-bottom: $value * 1%;
    }
}
// END Margin

// Font size
@for $i from 1 through 30 {
    $value: $i * 2;
    .fs#{$value}p {
        font-size: $value * 1px !important;
    }
}

@for $i from 1 through 10 {
    .line-clamp-#{$i} {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: $i;
        -webkit-box-orient: vertical;
    }
}

.hide-empty:empty {
    display: none;
}
