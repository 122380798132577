@import './variables';
@import './mixins';
@import 'entity-list';

$markerLabelWidth: 120px;
$iconSize: 50px;

.map-marker {
    cursor: pointer;
    position: absolute;
    top: -54px;
    left: -31px;
    &-icon-container {
        position: relative;
        top: 8px;
        .border {
            font-size: $iconSize + 4px;
            left: -7px;
            top: -9px;
        }
        .background {
            font-size: $iconSize;
            top: -7px;
            left: -4px;
        }
        .icon {
            font-size: 20px;
            top: 3px;
            left: 17px;
        }
        .badge {
            @include mapPinBadgeBottomRight(25px, white);
            font-size: 12px;
        }
        .badge-wisp {
            @include mapPinBadgeTopLeft(25px, white, $W_DK_GRAY);
            font-size: 12px;
        }
        .badge-person-hw {
            @include mapPinBadgeTopLeft(25px, white, $W_DK_BLUE);
            font-size: 12px;
        }
        .badge-item-hw {
            @include mapPinBadgeBottomRight(25px, white, $W_DK_BLUE);
            font-size: 12px;
        }
        .badge-working {
            @include mapPinBadgeTopLeft(25px, white, $ORANGE);
            font-size: 12px;
        }
        .badge-idle {
            @include mapPinBadgeTopLeft(25px, white, $GRAY);
            font-size: 12px;
        }
        .badge-wilma {
            @include mapPinBadgeTopLeft(25px, white, $ORANGE);
            font-size: 12px;
        }
        .badge-walt-on {
            @include mapPinBadgeTopRight(20px, white, $GREEN);
            font-size: 12px;
        }
        .badge-walt-off {
            @include mapPinBadgeTopRight(20px, white, $RED);
            font-size: 12px;
        }
    }
    &-label {
        position: absolute;
        top: 52px;
        right: -64px;
        overflow: hidden;
        border-radius: $border-radius;
    }
    &.selected {
        transform: scale(1.25);
        top: -65px;
        left: -36px;
        z-index: $z800;
        .map-marker-label {
            background: $DK_GRAY;
        }
    }
}

.map-marker-callout {
    background-color: $DK_GRAY;
    border-radius: $border-radius $border-radius 0 $border-radius;
    padding: 20px;
    position: absolute;
    min-width: 230px;
    min-height: 200px;
    z-index: $z800;
    transition: all .25s ease-out;
    transform: translate(-100%, calc(-100% - 110px));
    filter: drop-shadow(1px 1px 0px white) drop-shadow(-1px -1px 0px white) drop-shadow(-1px 1px 0px white) drop-shadow(1px -1px 0px white);

    &::before {
        content: "";
        width: 0px;
        height: 0px;
        border: 100px solid transparent;
        position: absolute;
        right: 0;
        bottom: -30px;
        border-right: 100px solid $DK_GRAY;
        z-index: -1;
    }

    &-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        gap: 10px;
        margin-top: 10px;

        button {
            &.btn {
                .btn-icon {
                    &:not(.hover) {
                        display: block;
                    }

                    &.hover {
                        display: none;
                    }
                }

                &:hover {
                    .btn-icon {
                        &:not(.hover) {
                            display: none;
                        }

                        &.hover {
                            display: block;
                        }
                    }
                }
            }
            
        }
    }

    .close-callout {
        position: absolute;
        top: 4px;
        right: 10px;
        font-size: $font-size-16;
    }
}

.marker-info-content {
    font-size: $font-size-12;
    .item {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding-bottom: 5px;
        &:last-child {
            padding-bottom: 0px;
        }
        &-label {
            font-weight: $font-weight-400;
        }
        &-value {
            font-weight: $font-weight-300;
        }

        &.actions {
            width: 100%;
            padding-bottom: 5px;
            margin-bottom: 8px;
            border-bottom: 1px solid $MED_GRAY;
        }
    }
}

.marker-info-content {
    font-size: $font-size-12;
    .item {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding-bottom: 5px;
        &:last-child {
            padding-bottom: 0px;
        }
        &-label {
            font-weight: $font-weight-400;
        }
        &-value {
            font-weight: $font-weight-300;
        }

        &.actions {
            width: 100%;
            padding-bottom: 5px;
            margin-bottom: 8px;
            border-bottom: 1px solid $MED_GRAY;
        }
    }
}

.info-window-content.has-actions {
    padding-top: 10px;

    .delete {
        position: absolute;
        top: 0;
        right: 22px;
        padding: 0;
        font-size: 11px;
    }
}

.common-map-label {
    width: $markerLabelWidth;
    text-align: center;
    font: $font-weight-200 $font-size-14 Gilmer;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical; // Found this here -> https://github.com/postcss/autoprefixer/issues/776; style was getting removed and not behaving properly
}

.map-label {
    @extend .common-map-label;
}

#infoWindowContainer {
    max-height: 250px !important;
}

.map-label-selected {
    @extend .common-map-label;
    font: $font-weight-400 $font-size-20 Gilmer;
}

.gm-style .gm-style-iw-c {
    background-color: $GRAY_DK;
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style .gm-style-iw-c button img {
    filter: brightness(0) invert(1);
}

.gm-ui-hover-effect:hover {
    opacity: .6;
}

.gm-style .gm-style-iw-t::after {
    background: $GRAY_DK;
}

.map-avatar {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    left: 15.5px;
    filter: unset !important;
}

.map-avatar-initials {
    position: absolute;
    display: flex;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid $WHITE;
    color: var(--avatar-font-color);
    background-color: var(--avatar-background-color);
    left: 15.5px;
    .text {
        margin: auto;
        font-size: $font-size-14;
    }
}

/* Google map info window overrides */
.gm-style {
    font-family: 'Gilmer', sans-serif;
}

.gm-style .gm-style-iw-c {
    width: 250px;
    padding: 0;
    transform: translate3d(-110%,-50%,0);
    background: $W_DK_GRAY;
    border-radius: $border-radius;
    border: 1px solid $WHITE;
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important;
    padding: 0;
    background: $W_DK_GRAY!important;
}

.gm-style .gm-style-iw-tc::after {
    content: none;
}

.gm-ui-hover-effect {
    right: 10px!important;
    top: 5px!important;
    height: 25px!important;
    width: 25px!important;
    span {
        background-color: white!important;
        height: 25px!important;
        width: 25px!important;
        margin: 0!important;
    }
}

.teams, .teams-dark {
    .gm-style .gm-style-iw-c {
        border-radius: 0;
        border-color: var(--map-callout-bkg-color);
        filter: drop-shadow(2px 2px 10px var(--map-callout-shadow-color));
        transform: translate3d(-115%, -50%, 0);
    }

    .gm-ui-hover-effect {
        span {
            background-color: var(--map-callout-color) !important;
        }
    }
}
